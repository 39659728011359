.stackListContainer {
  width: 100%;
  border-radius: var(--border-rd);
  background: var(--black);
  padding: var(--box-pd) 0;
}

.front-position {
  grid-area: frontList;
}

.back-position {
  grid-area: backList;
}

.soft-position {
  grid-area: softList;
}

.title {
  font-size: 2em;
  padding: 0 var(--box-pd) var(--grid-gap);
}

.listContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.front-color .indicator {
  background: var(--blue);
}

.front-color.techContainer:hover {
  background: var(--blue);
}

.front-color:hover .indicator {
  background: var(--black1);
}

.front-color:hover p {
  color: var(--black1);
}

.back-color .indicator {
  background: var(--yellow);
}

.back-color.techContainer:hover {
  background: var(--yellow);
}

.back-color:hover .indicator {
  background: var(--black1);
}

.back-color:hover p {
  color: var(--black1);
}

.soft-color .indicator {
  background: var(--pink);
}

.soft-color.techContainer:hover {
  background: var(--pink);
}

.soft-color:hover .indicator {
  background: var(--black1);
}

.soft-color:hover p {
  color: var(--black1);
}

.techContainer {
  width: 100%;
  display: flex;
  border-top: 1px solid #282828;
  border-bottom: 1px solid #282828;
  padding: 7px var(--box-pd);
  align-items: center;
  transition: 0.3s;
}

.techContainer p {
  color: var(--gray);
  font-size: 1.2em;
}

.indicator {
  width: 6px;
  height: 50px;
  margin-right: var(--box-pd);
}
