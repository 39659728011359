.wrapper {
  align-content: flex-start;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  align-items: stretch;
  display: flex;
}
.container {
  max-width: var(--max-width);
  min-height: 600px;
  justify-content: center;
  align-items: flex-end;
  align-content: flex-end;
  padding-bottom: 50px;
  display: flex;
  flex-wrap: wrap;
}
.logo {
  font-size: 1.5em;
  font-weight: 500;
  text-align: center;
}
.s {
  font-size: 1em;
  font-weight: 500;
  text-align: center;
  padding-bottom: 1px;
  padding-left: 3px;
  color: var(--blue);
}
.joke {
  font-size: 1.1em;
  font-weight: 500;
  width: 100%;
  text-align: center;
  padding-bottom: 40px;
}
.copyrigth {
  text-align: center;
  width: 100%;
  font-size: 0.6em;
  letter-spacing: 4px;
  font-weight: 400;
}
.powered {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-bottom: 30px;
  width: 100%;
}
.icons {
  margin-top: 20px;
}
.react {
  margin-right: 20px;
}
.logoCont {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 10px;
}
