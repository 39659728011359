/* Montserrat */
@font-face {
  font-family: Montserrat;
  font-weight: 100;
  font-style: normal;
  src: url('assets/fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-weight: 500;
  font-style: normal;
  src: url('assets/fonts/Montserrat-Bold.ttf') format('truetype');
}
