.container {
  border-radius: var(--border-rd);
  width: 100%;
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  padding: var(--box-pd);
  justify-content: space-between;
  z-index: 1;
  margin-bottom: var(--grid-gap);
}

.gitlab-gr {
  background-image: var(--yellow-gr-3);
}

.github-gr {
  background-image: var(--black-gr-2);
}

.logoContainer {
  z-index: 3;
  display: flex;
  width: 240px;
}

.logoContainer img {
  width: 100%;
  object-fit: cover;
}

.url {
  font-weight: bold;
  z-index: 3;
  text-align: center;
  font-size: 1.8em;
}

.background {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 1400px) {
  .logoContainer {
    width: 150px;
  }
}

@media (max-width: 650px) {
  .logoContainer {
    width: 200px;
  }
  .url {
    font-size: 1.5em;
  }
}
