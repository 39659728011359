.wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: var(--grid-columns);
  grid-template-rows: 10% repeat(3, [row] 30%);
  grid-template-areas:
    'personal header header'
    'personal . .'
    'personal . glewee'
    '. . lenio';
  align-content: start;
  column-gap: var(--grid-gap);
  row-gap: var(--grid-gap);
  position: relative;
}

.title {
  grid-area: header;
  color: var(--yellow);
  font-size: 3.5em;
  font-weight: 500;
}

.visualizer {
  position: absolute;
  bottom: 0;
}

@media (max-width: 1400px) {
  .wrapper {
    grid-template-rows: repeat(6, auto);
    grid-template-areas:
      'header header'
      'personal .'
      'personal .'
      '. .'
      'glewee lenio';
  }
  .visualizer {
    top: 0;
    bottom: unset;
  }
}

@media (max-width: 950px) {
  .wrapper {
    grid-template-rows: 0.1fr 1fr repeat(6, 0.4fr);
    grid-template-areas:
      'header'
      'personal'
      '.'
      '.'
      '.'
      '.'
      '.'
      'glewee'
      'lenio';
  }
  .title {
    text-align: center;
  }
}
