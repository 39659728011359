.container {
  border-radius: var(--border-rd);
  padding: var(--box-pd);
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.oxoft_gr {
  background-image: var(--black-gr);
}

.keiron_gr {
  background-image: var(--red-gr);
}

.galindo_gr {
  background-image: var(--green-gr);
}

.glewee_gr {
  background-image: var(--rainbow-gr);
}

.lenio_gr {
  background-image: var(--lenio-gr);
}

.bgImage {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--border-rd);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 3;
}

.titleAndLogo {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.titleAndLogo h4 {
  font-weight: bold;
  line-height: 1;
}

.logoContainer {
  display: flex;
  width: 100px;
}

.logoContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content > h5 {
  text-align: center;
  color: var(--black-1);
  font-weight: bold;
  font-size: 1.3em;
  padding: 30px 0;
}

.description {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
}

.description > p {
  padding-bottom: 5px;
}
.description p {
  color: var(--white);
  font-size: 1.3em;
  text-align: left;
}

@media (max-width: 500px) {
  .content > h5 {
    font-size: 1.5em;
  }
  .titleAndLogo h4 {
    font-size: 2em !important;
    order: 2;
  }
  .titleAndLogo {
    align-items: flex-start !important;
    flex-wrap: wrap;
  }
  .logoContainer {
    margin-bottom: 10px;
    margin-right: 10px;
    order: 1;
  }
  .description {
    flex-wrap: wrap;
  }
}
