* {
  padding: 0;
  margin: 0;
  border: 0;
  font-size: 20px;
  font: inherit;
  color: var(--white);
  color: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  font-family: Montserrat;
  font-weight: 100;
  scroll-behavior: smooth;
  scrollbar-color: var(--strong-color) rgba(0, 0, 0, 0);
  scrollbar-width: thin;
  line-height: 1.1;
}

*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  display: none;
}

*::-webkit-scrollbar-thumb {
  background: var(--strong-color);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb:hover {
  background: #a0a0a0;
}

*:link {
  text-decoration: none;
}

*:active {
  border: none;
  outline: none;
}

html {
  scrollbar-color: var(--strong-color) var(--black);
  height: 100%;
  background: var(--black1);
}

ol,
ul {
  list-style: none;
}

body {
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Montserrat;
}

:root {
  /* UTILITIES */
  --max-width: 1600px;
  --border-rd: 20px;
  --box-pd: 30px;
  --box-pd-2: 20px;
  --grid-gap: 40px;
  --grid-columns: repeat(3, 1fr);

  /* COLOR PALETTE */
  --black: #1d1d1d;
  --black1: #000000;
  --gray: #b4b4b4;
  --gray2: #9a9a9a;
  --white: #ffffff;
  --yellow: #ffc90c;
  --blue: #46e9ff;
  --blue-2: #376f9f;
  --pink: #ff1994;
  --red: #b20a2c;
  --orange: #ff7010;

  /* GRADIENTS */
  --green-gr: linear-gradient(124.44deg, #348f50 1.06%, #56b4d3 97.63%);
  --green-gr-2: linear-gradient(180deg, #1f4037 0%, #99f2c8 100%);
  --green-gr-3: linear-gradient(180deg, #44a08d 0%, #093637 97.49%);
  --gray-gr: linear-gradient(122.9deg, #bbd2c5 4.3%, #536976 54.14%, #292e49 95.18%);
  --black-gr: linear-gradient(180deg, #171717 0%, #505050 100%);
  --black-gr-2: linear-gradient(180deg, #232526 0%, #414345 100%);
  --blue-gr: linear-gradient(180deg, #1d1d1d 0%, #294176 100%);
  --blue-gr-2: linear-gradient(180deg, #5433ff 0%, #20bdff 54.17%, #a5fecb 100%);
  --blue-gr-3: linear-gradient(180deg, #a8c0ff 0%, #3f2b96 100%);
  --blue-gr-4: linear-gradient(90deg, #2980b9 0%, #6dd5fa 50.55%, #ffffff 99.36%);
  --rainbow-gr: linear-gradient(
    221.72deg,
    rgba(18, 194, 233, 0.6) 11.38%,
    rgba(196, 113, 237, 0.6) 54.94%,
    rgba(246, 79, 89, 0.6) 96.72%
  );
  --rainbow-gr-2: linear-gradient(162.51deg, #12c2e9 -6.08%, #c471ed 57.9%, #f64f59 121.87%);
  --rainbow-gr-3: linear-gradient(180deg, #00c3ff 0%, #ffff1c 100%);
  --rainbow-gr-4: linear-gradient(180deg, #aa4b6b 0%, #6b6b83 54.17%, #3b8d99 100%);
  --yellow-gr: linear-gradient(180deg, #ffe259 0%, #ffa751 100%);
  --yellow-gr-2: linear-gradient(90deg, #ffe259 0%, #ffa751 100%);
  --yellow-gr-3: linear-gradient(180deg, #fceabb 0%, #f8b500 100%);
  --yellow-gr-4: linear-gradient(180deg, #636363 0%, #a2ab58 100%);
  --purple-gr: linear-gradient(180deg, #544a7d 0%, #ffd452 100%);
  --purple-gr-2: linear-gradient(180deg, #654ea3 0%, #eaafc8 100%);
  --purple-gr-3: linear-gradient(180deg, #3f2b96 0%, #a8c0ff 100%);
  --orange-gr: linear-gradient(180deg, #ff9966 0%, #ff5e62 100%);
  --red-gr: linear-gradient(180deg, #b20a2c 0%, #fffbd5 149.25%);
  --pink-gr: linear-gradient(90deg, #ec008c 0%, #fc6767 66.74%);
  --pink-gr-2: linear-gradient(180deg, #bc4e9c 0%, #f80759 100%);
  --white-gr: linear-gradient(180deg, #8e9eab 0%, #eef2f3 147%);
  --lenio-gr: linear-gradient(90deg, #233042 0%, #eef2f3 147%);
}

@media (max-width: 1400px) {
  :root {
    /* UTILITIES */
    --max-width: 95%;
    --grid-gap: 40px;
    --grid-columns: repeat(2, 1fr);
  }
}

@media (max-width: 950px) {
  :root {
    /* UTILITIES */
    --max-width: 85%;
    --grid-gap: 40px;
    --grid-columns: 1fr;
  }
}

#root {
  width: 100%;
  max-height: 100%;
  height: 100%;
}
