.wrapper {
  align-items: flex-start;
  align-content: flex-start;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left bottom;
  width: 100%;
  display: flex;
  margin-bottom: var(--grid-gap);
}
.container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  width: var(--max-width);
  min-height: var(--height);
  justify-content: space-between;
  align-items: flex-start;
}
.top {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 30px;
  align-items: flex-start;
}
.brack {
  font-size: 2em;
  color: var(--orange);
  font-weight: 500;
  line-height: 0;
}
.logo {
  font-weight: 500;
  font-size: 2em;
  padding: 0 3px;
}
.word {
  font-size: 4em;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--blue);
}
.carlos {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 4em;
}
.messageCont {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.message {
  display: flex;
  width: 30%;
  flex-wrap: wrap;
}
.logoContainer {
  display: flex;
  width: 350px;
}
.logoContainer img {
  width: 100%;
  object-fit: cover;
}

@media (max-width: 950px) {
  .top {
    justify-content: center;
  }
  .messageCont {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  }
  .messageCont .message {
    width: 100%;
    margin-bottom: 100px;
  }
  .messageCont .message .word {
    text-align: center;
  }
}

@media (max-width: 650px) {
  .messageCont .message .word {
    font-size: 3em;
  }
  .messageCont .message {
    margin-bottom: 50px;
  }
}

@media (max-width: 450px) {
  .messageCont .message .word {
    font-size: 2.5em;
  }
}
