.container {
  position: relative;
  width: 100%;
  padding: var(--box-pd);
  border-radius: var(--border-rd);
  background: var(--black);
  margin-top: var(--grid-gap);
}

.title {
  font-size: 1.5em;
  padding-bottom: var(--grid-gap);
}

.cardsContainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: var(--box-pd);
}

.visualizer {
  position: absolute;
  bottom: 0;
}

@media (max-width: 1400px) {
  .cardsContainer {
    grid-template-columns: repeat(5, 1fr);
    row-gap: var(--grid-gap);
  }
}

@media (max-width: 1150px) {
  .cardsContainer {
    grid-template-columns: repeat(4, 1fr);
  }
  .visualizer {
    bottom: unset;
    top: 0;
  }
}

@media (max-width: 950px) {
  .cardsContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 700px) {
  .cardsContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 550px) {
  .cardsContainer {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }
  .title {
    text-align: center;
  }
}
