.cardContainer {
  border-radius: var(--border-rd);
  background: var(--black);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.visualizator {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.bannerContainer {
  width: 100%;
  display: flex;
  margin-bottom: var(--box-pd);
}

.bannerContainer img {
  width: 100%;
  object-fit: contain;
}

.cardContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0 var(--box-pd) var(--box-pd);
}

.statusCont {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 10px;
}

.statusCont p {
  padding-left: 5px;
  color: var(--blue);
}

.statusCont p:nth-child(1) {
  padding-left: 0;
}

.cardContent h6 {
  color: var(--white);
  font-size: 2em;
  padding-bottom: 15px;
}

.descriptionCont {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.descriptionCont p {
  padding-bottom: 10px;
  color: var(--gray2);
  text-align: justify;
  font-size: 1.1em;
}

.buttonsCont {
  width: 85%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
  align-self: center;
  margin-top: var(--box-pd);
}

.centerSelf {
  grid-column-start: 2;
}

.linkCont,
.companyCont,
.roleCont {
  width: 100%;
  border-radius: 15px;
  background: var(--black1);
  padding-bottom: 100%;
  position: relative;
}

.linkCont {
  background: var(--purple-gr-3);
  transition: 0.3s;
}

.linkCont.animate:hover {
  cursor: pointer;
  transform: scale(0.9);
}

.linkCont.noAnimate {
  opacity: 0.3;
}

.linkCont .iconCont {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.linkCont img {
  object-fit: contain;
  width: 80%;
}

.companyCont .logoCont {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.companyCont .logoCont img {
  object-fit: contain;
  width: 80%;
}

.glewee-bg {
  background: transparent;
}

.glewee-bg .logoCont img {
  width: 100%;
}

.personal-bg {
  background: var(--rainbow-gr-3);
}

.keiron-bg {
  background: var(--red-gr);
}

.oxoft-bg {
  background: var(--white-gr);
}

.glewee-bg,
.keiron-bg,
.oxoft-bg {
  transition: 0.3s;
}

.glewee-bg:hover,
.keiron-bg:hover,
.oxoft-bg:hover {
  transform: scale(0.9);
  cursor: pointer;
}

.roleCont .contentCont {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.roleAndIcon {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.roleAndIcon p {
  text-align: center;
  font-weight: bold;
  font-size: 0.9em;
}

.roleAndIcon img {
  width: 30px;
  padding-bottom: 10px;
}

.roleCont.altBg {
  background: var(--rainbow-gr-4);
}

.roleCont.regularBg {
  background: var(--pink-gr-2);
}

@media (max-width: 800px) {
  .visualizator {
    bottom: unset;
    top: 0;
  }
  .buttonsCont {
    width: 100%;
  }
}
