.gmail-bg {
  background-image: var(--green-gr-3);
}
.torre-bg {
  background-image: var(--yellow-gr-4);
}
.breal-bg {
  background-image: var(--black-gr-2);
}
.ig-bg {
  background-image: var(--rainbow-gr-2);
}

.cardContainer {
  width: 100%;
  border-radius: var(--border-rd);
  padding: var(--box-pd);
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-bottom: var(--grid-gap);
}

.cardContainer .content {
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.cardContainer .logoContainer {
  display: flex;
  align-self: flex-end;
  width: 70px;
}

.cardContainer .logoContainer img {
  width: 100%;
  display: flex;
  object-fit: cover;
}

.cardContainer .urlsContainer {
  display: flex;
  flex-direction: column;
}
.cardContainer .urlsContainer p {
  font-weight: bold;
  align-self: flex-start;
  font-size: 1.2em;
  padding-top: 10px;
  color: var(--white);
}

.cardContainer .background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: space;
  background-position: center;
  z-index: 2;
}

@media (max-width: 950px) {
  .ig-bg .logoContainer,
  .gmail-bg .logoContainer,
  .torre-bg .logoContainer {
    width: 85px !important;
  }

  .torre-bg .urlsContainer p,
  .ig-bg .urlsContainer p,
  .breal-bg .urlsContainer p {
    font-size: 1.5em;
  }
}
