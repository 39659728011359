.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  color: var(--blue);
  font-size: 3.5em;
  font-weight: 500;
  align-self: flex-start;
}
