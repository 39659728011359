.contactAndPortfolioContainer {
  width: 100%;
  display: grid;
  grid-template-columns: var(--grid-columns);
  column-gap: var(--grid-gap);
  grid-template-rows: repeat(2, 0.5fr) 1fr repeat(2, 0.9fr);
  grid-template-areas:
    'card1 card2 title'
    'card1 card2 bio'
    'linkedin linkedin bio'
    '. . bio'
    'title2 . .';
  position: relative;
}

.title {
  color: var(--orange);
  font-size: 3.5em;
  font-weight: 500;
  align-self: flex-start;
  text-align: right;
  grid-area: title;
}

.portfolio {
  color: var(--yellow);
  font-size: 3.5em;
  font-weight: 500;
  align-self: flex-end;
  text-align: left;
  grid-area: title2;
  margin-bottom: var(--grid-gap);
}

.bioContainer {
  width: 100%;
  border-radius: var(--border-rd);
  background: var(--black);
  padding: calc(var(--box-pd) + 10px);
  grid-area: bio;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-bottom: var(--grid-gap);
}

.bioContainer .content {
  z-index: 3;
}

.bioContainer p {
  width: 100%;
  text-align: justify;
  padding-bottom: 30px;
  font-size: 1.2em;
}

.bioContainer .logoContainer {
  display: flex;
  width: 100px;
  align-self: flex-end;
  z-index: 3;
}

.bioContainer .logoContainer img {
  width: 100%;
  object-fit: cover;
}

.bioBackground {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  z-index: 2;
}

.linkedinContainer {
  width: 100%;
  grid-area: linkedin;
  border-radius: var(--border-rd);
  padding: var(--box-pd);
  background-image: var(--blue-gr-4);
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-bottom: var(--grid-gap);
}

.linkedinContainer .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
  position: relative;
}

.linkedinContainer .logoContainer {
  display: flex;
  align-self: flex-end;
  width: 200px;
  margin-bottom: 55px;
}

.linkedinContainer .logoContainer img {
  width: 100%;
  display: flex;
  object-fit: cover;
}

.linkedinContainer .url {
  font-weight: bold;
  font-size: 2em;
  align-self: flex-start;
}

.linkedinContainer .background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 2;
}

.visualizer {
  position: absolute;
  bottom: 0;
}

@media (max-width: 1400px) {
  .contactAndPortfolioContainer {
    grid-template-rows: 0.2fr repeat(2, 0.5fr) 0.6fr repeat(2, 0.5fr);
    grid-template-areas:
      'title title'
      'card1 bio'
      'card2 bio'
      'linkedin linkedin'
      '. .'
      '. .'
      'title2 .';
  }

  .linkedinContainer .content {
    height: 100%;
    justify-content: space-between;
  }

  .linkedinContainer .logoContainer {
    margin-bottom: 0;
  }
  .linkedinContainer .url {
    font-size: 1.5em;
  }
}

@media (max-width: 950px) {
  .visualizer {
    bottom: unset;
    top: 0;
  }
  .contactAndPortfolioContainer {
    grid-template-rows: 0.2fr 1fr repeat(7, 0.5fr);
    grid-template-areas:
      'title'
      'bio'
      'card1'
      'card2'
      'linkedin'
      '.'
      '.'
      '.'
      '.'
      'title2';
  }
}

@media (max-width: 650px) {
  .linkedinContainer .url {
    font-size: 1.2em;
    color: var(--black);
  }
}
