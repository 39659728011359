.container {
  border-radius: var(--border-rd);
  background: var(--black);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  grid-area: personal;
}

.imageContainer {
  width: 100%;
  display: flex;
  overflow: hidden;
  border-top-right-radius: var(--border-rd);
  border-top-left-radius: var(--border-rd);
}

.imageContainer img,
.logoContainer img {
  width: 100%;
  object-fit: cover;
}
.contentContainer {
  padding: var(--box-pd);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.logoContainer {
  width: 90%;
  display: flex;
  overflow: hidden;
  padding: 0 0 80px 0;
}

.overviewContainer {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 80%;
}

.flagContainer {
  width: 30%;
  display: flex;
  padding-bottom: 30%;
  position: relative;
  border-radius: var(--border-rd);
  overflow: hidden;
}
.flagContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

.yearsContainer {
  width: 30%;
  background-image: var(--green-gr);
  padding: 10;
  border-radius: var(--border-rd);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.yearsContainer h5,
.englishContainer h5 {
  color: var(--white);
  font-weight: 500;
  font-size: 3em;
}

.yearsContainer p,
.englishContainer p {
  color: var(--black1);
  text-align: center;
  width: 100%;
  font-size: 0.9em;
}

.englishContainer {
  background-image: var(--gray-gr);
  padding: 10;
  width: 30%;
  border-radius: var(--border-rd);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 650px) {
  .overviewContainer {
    width: 90%;
  }
}

@media (max-width: 500px) {
  .logoContainer {
    width: 100%;
    padding-bottom: 40px;
  }

  .overviewContainer {
    width: 100%;
  }
}
