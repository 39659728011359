.wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
}

.container {
  width: 100%;
  max-width: var(--max-width);
  overflow: hidden;
  margin-top: var(--grid-gap);
}

.noMargin {
  width: 100%;
  max-width: var(--max-width);
  overflow: hidden;
}
