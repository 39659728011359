.bannerContainer {
  width: 100%;
  border-radius: var(--border-rd);
  overflow: hidden;
}

.front-gr {
  background-image: var(--blue-gr-2);
  grid-area: frontBanner;
}

.back-gr {
  background-image: var(--yellow-gr-2);
  grid-area: backBanner;
}

.soft-gr {
  background-image: var(--pink-gr);
  grid-area: softBanner;
}

.imageContainer {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.imageContainer img {
  object-fit: cover;
  width: 100%;
}
