.nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}
.menuItem:hover {
  color: var(--blue);
}
.menuItem {
  padding-top: 80px;
  text-transform: uppercase;
  font-size: 1.2em;
  font-weight: 500;
  width: 100%;
  text-align: right;
  cursor: pointer;
  transition: 0.6s;
}
.menuItem:nth-child(1) {
  padding-top: 0;
}
@media (max-width: 950px) {
  .nav {
    flex-direction: row;
    width: 100%;
    justify-content: center;
  }

  .nav ol {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .nav .menuItem {
    text-align: center;
    padding-top: 0;
  }
}

@media (max-width: 650px) {
  .nav ol {
    width: 100%;
    display: flex;
    align-items: center;
    width: 50;
    flex-direction: column;
  }

  .nav .menuItem {
    text-align: center;
    padding-top: 50px;
  }
}
