.container {
  border-radius: var(--border-rd);
  background: var(--black);
  padding: var(--box-pd);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.container h3 {
  font-size: 2.7em;
  font-weight: 500;
  padding-bottom: 20px;
}

.description p {
  font-size: 1.3em;
}

.description p:nth-child(1) {
  padding-bottom: 10px;
}

.engineer_gr {
  background-image: var(--black-gr);
}

.blockchain_gr {
  background-image: var(--blue-gr);
}

@media (max-width: 500px) {
  .container h3 {
    font-size: 1.8em;
  }
}
