.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.portfolio {
  color: var(--blue);
  font-size: 3.5em;
  font-weight: 500;
  align-self: flex-end;
  text-align: left;
  grid-area: title2;
  margin-bottom: var(--grid-gap);
}

.cardsContainer {
  width: 100%;
  display: grid;
  grid-template-columns: var(--grid-columns);
  grid-auto-rows: min-content;
  column-gap: var(--grid-gap);
  row-gap: var(--grid-gap);
  align-items: flex-start;
}

.gridColumn {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  row-gap: var(--grid-gap);
}
