.gridContainer {
  margin-top: var(--grid-gap);
  width: 100%;
  display: grid;
  grid-template-columns: var(--grid-columns);
  grid-template-rows: repeat(4, 1fr);
  row-gap: var(--grid-gap);
  column-gap: var(--grid-gap);
  grid-template-areas:
    'frontList backBanner softList'
    'frontList backList softList'
    'frontList backList softList'
    'frontBanner backList softBanner';
  position: relative;
}

.visualizer {
  position: absolute;
  bottom: 0;
}

@media (max-width: 1400px) {
  .gridContainer {
    grid-template-rows: repeat(8, 1fr);
    grid-template-areas:
      'frontBanner backList'
      'frontList backList'
      'frontList backList'
      'frontList backBanner'
      'softBanner .'
      'softList .'
      'softList .'
      'softList .';
  }
  .visualizer {
    bottom: unset;
    top: 0;
  }
}

@media (max-width: 950px) {
  .gridContainer {
    grid-template-areas:
      'frontBanner'
      'frontList'
      'frontList'
      'frontList'
      'backBanner'
      'backList'
      'backList'
      'backList'
      'softBanner'
      'softList'
      'softList'
      'softList';
  }
}

@media (max-width: 600px) {
  .gridContainer {
    grid-template-rows: 0.65fr repeat(3, 1fr) 0.65fr repeat(3, 1fr) 0.65fr repeat(3, 1fr);
  }
}
