.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.cardsContainer {
  width: 100%;
  display: grid;
  grid-template-columns: var(--grid-columns);
  grid-auto-rows: min-content;
  column-gap: var(--grid-gap);
  row-gap: var(--grid-gap);
  align-items: flex-start;
}

.gridColumn {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  row-gap: var(--grid-gap);
}

.portfolio {
  color: var(--yellow);
  font-size: 3.5em;
  font-weight: 500;
  grid-area: title2;
}

.filterContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--grid-gap);
}
