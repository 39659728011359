.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.companyCircle {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  background: white;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.companyCircle:last-child {
  margin-right: 0;
}

.active {
  opacity: 1;
}

.hoverable {
  opacity: 0.5;
  transform: scale(0.7);
}

.hoverable:hover {
  opacity: 1;
  transform: scale(1);
}

.keiron {
  background: var(--red);
}

.keiron img {
  display: flex;
  object-fit: contain;
  width: 90%;
}

.oxoft img {
  display: flex;
  object-fit: contain;
  width: 90%;
}

.glewee img {
  display: flex;
  object-fit: contain;
  width: 100%;
}

.personal {
  background: var(--rainbow-gr-3);
}

.personal img {
  display: flex;
  object-fit: contain;
  width: 70%;
}
