.container {
  width: 100%;
  border-radius: var(--border-rd);
  padding: var(--box-pd-2);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 20% 80%;
  height: 350px;
  justify-content: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.js-bg {
  background: var(--blue-gr-2);
}

.ts-bg {
  background: var(--blue-gr-3);
}

.py-bg {
  background: var(--yellow-gr);
}

.php-bg {
  background: var(--purple-gr);
}

.sql-bg {
  background: var(--orange-gr);
}

.sol-bg {
  background: var(--purple-gr-2);
}

.sol-bg .logo {
  width: 65%;
}

.title {
  font-weight: bold;
  font-size: 2em;
  color: var(--white);
  text-align: center;
  z-index: 3;
}

.title-py {
  color: var(--blue-2);
}

.logoContainer {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
}

.logo {
  display: flex;
  width: 80%;
}

.logo img {
  width: 100%;
  object-fit: cover;
}

.background {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@media (max-width: 600px) {
  .container {
    width: 80%;
  }
  .title {
    font-size: 2.4em;
  }
  .logo {
    width: 80% !important;
  }
}
